import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, of, startWith, switchMap, throwError } from 'rxjs';

import type { Community } from 'prayer-rotation-base';

import { getDeepestActivatedRouteSnapshot } from '../../utilities/get-deepest-activated-route-snapshot';

@Injectable( { providedIn: 'root' } )
export class CommunityStateService {
  private readonly router = inject( Router );

  readonly currentCommunityObservable = this.router.events.pipe(
    filter( event => event instanceof NavigationEnd ),
    startWith( undefined ),
    map( () => getDeepestActivatedRouteSnapshot( this.router.routerState.root.snapshot ) ),
    map( activatedRouteSnapshot => activatedRouteSnapshot.data[ 'community' ] as Community | undefined ),
    distinctUntilChanged()
  );

  readonly assertCurrentCommunityObservable = this.currentCommunityObservable
    .pipe( switchMap( community =>
      community !== undefined
        ? of( community )
        : throwError( () => new Error( 'Community is undefined' ) )
    ) );
}
